const drinks = [
    {
        "id": "0",
        "drink_name": "Asahi Super Dry 0,25",
        "drink_type": "Lager",
        "drink_description": "Light, refreshing and tasy. Ideal for a summer day. ",
        "drink_abv": "4.90%",
        "drink_price": " € 3.50 ",
        "drink_size": "2,5 dl",
        "img_url": "images/drinks/asahi.png",
        "category": "Beer&Cider"
    },
    {
        "id": "1",
        "drink_name": "Asahi Super Dry 0,5",
        "drink_type": "Lager",
        "drink_description": "Light, refreshing and tasy. Ideal for a summer day. ",
        "drink_abv": "4.90%",
        "drink_price": " € 6.00 ",
        "drink_size": "5,0 dl",
        "img_url": "images/drinks/asahi.png",
        "category": "Beer&Cider"
    },
    {
        "id": "2",
        "drink_name": "Uiltje Bird of Prey",
        "drink_type": "IPA",
        "drink_description": "Hoppy, fresh and tasty :)",
        "drink_abv": "5.50%",
        "drink_price": " € 5.50 ",
        "drink_size": "2,5 dl",
        "img_url": "images/drinks/uiltje.png",
        "category": "Beer&Cider"
    },
    {
        "id": "3",
        "drink_name": "Karmeliet Tripel",
        "drink_type": "Belgian Tripel",
        "drink_description": "The freshness of wheat, the creaminess of oats, and a spicy, lemony dryness, with 8,4% punch.",
        "drink_abv": "8.40%",
        "drink_price": " € 5.50 ",
        "drink_size": "3,3 dl",
        "img_url": "images/drinks/karmeliet.jpg",
        "category": "Beer&Cider"
    },
    {
        "id": "4",
        "drink_name": "David's Choice",
        "drink_type": "Depends on the season",
        "drink_description": "Check out the info around the bar, or ask one of your staff members whats ON now :)",
        "drink_abv": "6-9%",
        "drink_price": " € 5.50 ",
        "drink_size": "3,3 dl",
        "img_url": "images/drinks/wisselfles.jpg",
        "category": "Beer&Cider"
    },
    {
        "id": "6",
        "drink_name": "Brouwerij’t IJ, Ijwit",
        "drink_type": "Wheat Ale",
        "drink_description": "Super fresh, with coriander and lemon for a rich aroma of banana, citrus and spices.",
        "drink_abv": "6.50%",
        "drink_price": " € 5.50 ",
        "drink_size": "3,3 dl",
        "img_url": "images/drinks/ijwit.png",
        "category": "Beer&Cider",
    },
    {
        "id": "7",
        "drink_name": "Brouwerij’t IJ, Blondie",
        "drink_type": "Belgian Blond",
        "drink_description": "A real Blondie. A soft blond beer, with a fresh color and a light malty aroma. Lekker!",
        "drink_abv": "5.80%",
        "drink_price": " € 5.50 ",
        "drink_size": "3,3 dl",
        "img_url": "images/drinks/blondie.png",
        "category": "Beer&Cider",
    },
    {
        "id": "8",
        "drink_name": "Bird Nognietnaar Huismus",
        "drink_type": "American Brown",
        "drink_description": "A lightly roasted brown with tones of caramel, nuts and coffee.",
        "drink_abv": "6.4%",
        "drink_price": " € 5.50 ",
        "drink_size": "3,3 dl",
        "img_url": "images/drinks/bird.png",
        "category": "Beer&Cider",
    },
    {
        "id": "36",
        "drink_name": "Vandestreek Non-IPA",
        "drink_type": "Low alcohol beer",
        "drink_description": "Full and fresh, for the days when you want to keep a clear head. ",
        "drink_abv": "0.5%",
        "drink_price": " € 5.50 ",
        "drink_size": "3,3 dl",
        "img_url": "images/drinks/nonipa.png",
        "category": "Beer&Cider",
    },
    {
        "id": "37",
        "drink_name": "Brouwerij’t Vrijwit",
        "drink_type": "Low alcohol beer",
        "drink_description": "Fresh like a good wheat beer, for the days when you want to keep a clear head. ",
        "drink_abv": "0.5%",
        "drink_price": " € 5.50 ",
        "drink_size": "3,3 dl",
        "img_url": "images/drinks/nonipa.png",
        "category": "Beer&Cider",
    },
    {
        "id": "9",
        "drink_name": "Apple Bandit",
        "drink_type": "Cider",
        "drink_description": "Contains hints of baked apples and a touch of sweetness and acidity, light and refreshing.",
        "drink_abv": "5.50%",
        "drink_price": " € 4.00 ",
        "drink_size": "3,0 dl",
        "img_url": "images/drinks/applebandit.png",
        "category": "Beer&Cider",
    },
    {
        "id": "10",
        "drink_name": "White Wine",
        "drink_type": "Wine",
        "drink_description": "A nice, fruity Sauvignon Blanc.",
        "drink_abv": "11% - 14%",
        "drink_price": " € 5.00 ",
        "drink_size": "1,5 dl",
        "img_url": "images/drinks/whitewine.webp",
        "category": "Wine",
    },

    {
        "id": "35",
        "drink_name": "Red Wine",
        "drink_type": "Wine",
        "drink_description": "A light, floral red.",
        "drink_abv": "11% - 14%",
        "drink_price": " € 5.00 ",
        "drink_size": "1,5 dl",
        "img_url": "images/drinks/redwine.jpg",
        "category": "Wine",
    },
    {
        "id": "11",
        "drink_name": "Fritz Cola",
        "drink_type": "Soft Drink",
        "drink_description": "Cola, just a little bit different. Just the way we like it :)",
        "drink_abv": "non-alcoholic",
        "drink_price": " € 3.50 ",
        "drink_size": "2,0 dl",
        "img_url": "images/drinks/cola.png",
        "category": "Soft drinks",
    },
    {
        "id": "12",
        "drink_name": "Fritz Cola Light",
        "drink_type": "Soft Drink",
        "drink_description": "Cola, just a little bit different. Just the way we like it :)",
        "drink_abv": "non-alcoholic",
        "drink_price": " € 3.50 ",
        "drink_size": "2,0 dl",
        "img_url": "images/drinks/colalight.png",
        "category": "Soft drinks",
    },
    {
        "id": "13",
        "drink_name": "Fritz Limo",
        "drink_type": "Soft Drink",
        "drink_description": "A tasty, fizzy Orange drink! ",
        "drink_abv": "non-alcoholic",
        "drink_price": " € 3.50 ",
        "drink_size": "2,0 dl",
        "img_url": "images/drinks/limo.png",
        "category": "Soft drinks"
    },
    {
        "id": "14",
        "drink_name": "Spa Blauw",
        "drink_type": "Water",
        "drink_description": "Ice cold still water, what else can we say :)",
        "drink_abv": "non-alcoholic",
        "drink_price": " € 3.00 ",
        "drink_size": "2,0 dl",
        "img_url": "images/drinks/spablauw.png",
        "category": "Soft drinks"
    },
    {
        "id": "15",
        "drink_name": "Spa Rood",
        "drink_type": "Water",
        "drink_description": "Same as the previous one, but with some bubbles!",
        "drink_abv": "non-alcoholic",
        "drink_price": " € 3.00 ",
        "drink_size": "2,0 dl",
        "img_url": "images/drinks/sparood.png",
        "category": "Soft drinks"
    },
    {
        "id": "16",
        "drink_name": "Appelsap",
        "drink_type": "Fruit Juice",
        "drink_description": "Delicious and natural fruit juice, without additions.",
        "drink_abv": "non-alcoholic",
        "drink_price": " € 3.50 ",
        "drink_size": "2,0 dl",
        "img_url": "images/drinks/appelsap.png",
        "category": "Soft drinks"
    },
    {
        "id": "18",
        "drink_name": "Fentimans Ginger Ale",
        "drink_type": "Soft Drink",
        "drink_description": "Blows you away with its spicy gingery deliciousness!",
        "drink_abv": "non-alcoholic",
        "drink_price": " € 3.50 ",
        "drink_size": "2,5 dl",
        "img_url": "images/drinks/ginger-beer.png",
        "category": "Soft drinks"
    },
    {
        "id": "19",
        "drink_name": "ChariTea Black",
        "drink_type": "Ice Tea",
        "drink_description": "Fresh, fairtrade, organic black tea sweetened with a bit of agave syrup. ",
        "drink_abv": "non-alcoholic",
        "drink_price": " € 3.50 ",
        "drink_size": "3,3 dl",
        "img_url": "images/drinks/chariteablack.png",
        "category": "Soft drinks"
    },
    {
        "id": "20",
        "drink_name": "ChariTea Red",
        "drink_type": "Ice Tea",
        "drink_description": "Rooibos tea with passionfruit, sweetened with a bit of agave syrup.",
        "drink_abv": "non-alcoholic",
        "drink_price": " € 3.50 ",
        "drink_size": "3,3 dl",
        "img_url": "images/drinks/chariteared.png",
        "category": "Soft drinks"
    },
    {
        "id": "21",
        "drink_name": "Fevertree Tonic Classic",
        "drink_type": "Soft Drink",
        "drink_description": "Rich & complex in flavor, uses a handful of different botanicals to get that zing.",
        "drink_abv": "non-alcoholic",
        "drink_price": " € 3.50 ",
        "drink_size": "2,0 dl",
        "img_url": "images/drinks/fevertree.png",
        "category": "Soft drinks"
    },
    {
        "id": "22",
        "drink_name": "Fentimans Rose Lemonade",
        "drink_type": "Soft Drink",
        "drink_description": "The delicate and sweet flavor of rose is perfectly balanced by the freshness of the lemonade.",
        "drink_abv": "non-alcoholic",
        "drink_price": " € 3.50 ",
        "drink_size": "2,0 dl",
        "img_url": "images/drinks/roselemonade.png",
        "category": "Soft drinks"
    },
    {
        "id": "24",
        "drink_name": "Chocomel",
        "drink_type": "Soft Drink",
        "drink_description": "Your go-to silky smooth chocolate drink.",
        "drink_abv": "non-alcoholic",
        "drink_price": " € 3.50 ",
        "drink_size": "2,0 dl",
        "img_url": "images/drinks/chocomel.png",
        "category": "Soft drinks"
    },
    {
        "id": "25",
        "drink_name": "Espresso",
        "drink_type": "Coffee",
        "drink_description": "Concentrated, strong, a good wakeup call, brewed with Lot 61 coffee. What else is there to say :) ",
        "drink_abv": "non-alcoholic",
        "drink_price": " € 3.00 ",
        "drink_size": "30 ml",
        "img_url": "images/drinks/espresso.jpg",
        "category": "Coffee&Tea"
    },
    {
        "id": "26",
        "drink_name": "Lungo",
        "drink_type": "Coffee",
        "drink_description": "In case you're looking for a regular coffee :)",
        "drink_abv": "non-alcoholic",
        "drink_price": " € 3.00 ",
        "drink_size": "1,5 dl",
        "img_url": "images/drinks/lungo.png",
        "category": "Coffee&Tea"
    },
    {
        "id": "28",
        "drink_name": "Cappuccino",
        "drink_type": "Coffee",
        "drink_description": "Add some regular or oat milk and get your white coffee groove on!",
        "drink_abv": "non-alcoholic",
        "drink_price": " € 4.00 ",
        "drink_size": "2,0 dl",
        "img_url": "images/drinks/cappuccino.jpg",
        "category": "Coffee&Tea"
    },
    {
        "id": "29",
        "drink_name": "Tea",
        "drink_type": "Tea",
        "drink_description": "Ask for our tea selection in the bar. ",
        "drink_abv": "non-alcoholic",
        "drink_price": " € 3.00 ",
        "drink_size": "2,0 dl",
        "img_url": "images/drinks/tea.jpg",
        "category": "Coffee&Tea"
    },
    {
        "id": "30",
        "drink_name": "Vodka, Bols",
        "drink_type": "Strong",
        "drink_description": "Clean and pure palate, & it goes well in pretty much any mixed drink. ",
        "drink_abv": "40%",
        "drink_price": " € 4.50 ",
        "drink_size": "4 cl",
        "img_url": "images/drinks/vodka.png",
        "category": "Strong"
    },
    {
        "id": "31",
        "drink_name": "Rum, Havana Club",
        "drink_type": "Strong",
        "drink_description": "Our go-to rum for our long-drinks!",
        "drink_abv": "40%",
        "drink_price": " € 4.50 ",
        "drink_size": "4 cl",
        "img_url": "images/drinks/rum.jpg",
        "category": "Strong"
    },
    {
        "id": "32",
        "drink_name": "Whiskey, Jameson",
        "drink_type": "Strong",
        "drink_description": "Our blended Irish whiskey, with a sweet fragrance of malted barley and oak notes.",
        "drink_abv": "40%",
        "drink_price": " € 4.50 ",
        "drink_size": "4 cl",
        "img_url": "images/drinks/whiskey.png",
        "category": "Strong"
    },
]


export default drinks;