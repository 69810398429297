import React, {useState} from 'react';
import Heading from './Heading';
import Drink from './Drink';
import './App.css';
import drinks from  "../drinks"; 


function App() {

  const [data, setData] = useState('');
  // const [subtitle_state] = useState("&Radler, Cider, 0.0%")
  
  const [sidebarData, setSidebarData] = useState();

  function change_menu_title(drink_childdata) {
    setData(drink_childdata);
  }
 
  function highlightMenu(childMenuData) {
    setSidebarData(childMenuData);
  }

  // function createDrink(drink) {
  //     return <Drink key={drink.id} id={drink.id} img_url={drink.img_url} drink_name={drink.drink_name} drink_type={drink.drink_type} drink_description={drink.drink_description}
  //     abv={drink.drink_abv} drink_price={drink.drink_price} drink_size={drink.drink_size} drink_category={drink.category} visibilityCheck={taDaa} menuHighlight={highlightMenu}
  //   />
  // }


  return (
    <div className="App">
      <Heading title={data} setHighlight={sidebarData}/>
      <div className="drink-content">
      {drinks
      .filter(drink => drink.category === "Beer&Cider")
      .map(drink => <Drink 
                      key={drink.id} 
                      id={drink.id} 
                      img_url={drink.img_url} 
                      drink_name={drink.drink_name} 
                      drink_type={drink.drink_type} 
                      drink_description={drink.drink_description}
                      abv={drink.drink_abv} 
                      drink_price={drink.drink_price} 
                      drink_size={drink.drink_size} 
                      drink_category={drink.category} 
                      visibilityCheck={change_menu_title} 
                      menuHighlight={highlightMenu}
                    />)
      }
      <br></br>
      {drinks
      .filter(drink => drink.category === "Wine")
      .map(drink => <Drink 
                      key={drink.id} 
                      id={drink.id} 
                      img_url={drink.img_url} 
                      drink_name={drink.drink_name} 
                      drink_type={drink.drink_type} 
                      drink_description={drink.drink_description}
                      abv={drink.drink_abv} 
                      drink_price={drink.drink_price} 
                      drink_size={drink.drink_size} 
                      drink_category={drink.category} 
                      visibilityCheck={change_menu_title} 
                      menuHighlight={highlightMenu}
                    />)
      }
      <br></br>
      {drinks
      .filter(drink => drink.category === "Soft drinks")
      .map(drink => <Drink 
                      key={drink.id} 
                      id={drink.id} 
                      img_url={drink.img_url} 
                      drink_name={drink.drink_name} 
                      drink_type={drink.drink_type} 
                      drink_description={drink.drink_description}
                      abv={drink.drink_abv} 
                      drink_price={drink.drink_price} 
                      drink_size={drink.drink_size} 
                      drink_category={drink.category} 
                      visibilityCheck={change_menu_title} 
                      menuHighlight={highlightMenu}
                    />)
      }
      <br></br>
      {drinks
      .filter(drink => drink.category === "Coffee&Tea")
      .map(drink => <Drink 
                      key={drink.id} 
                      id={drink.id} 
                      img_url={drink.img_url} 
                      drink_name={drink.drink_name} 
                      drink_type={drink.drink_type} 
                      drink_description={drink.drink_description}
                      abv={drink.drink_abv} 
                      drink_price={drink.drink_price} 
                      drink_size={drink.drink_size} 
                      drink_category={drink.category} 
                      visibilityCheck={change_menu_title} 
                      menuHighlight={highlightMenu}
                    />)
      }
      <br></br>
      {drinks
      .filter(drink => drink.category === "Strong")
      .map(drink => <Drink 
                      key={drink.id} 
                      id={drink.id} 
                      img_url={drink.img_url} 
                      drink_name={drink.drink_name} 
                      drink_type={drink.drink_type} 
                      drink_description={drink.drink_description}
                      abv={drink.drink_abv} 
                      drink_price={drink.drink_price} 
                      drink_size={drink.drink_size} 
                      drink_category={drink.category} 
                      visibilityCheck={change_menu_title} 
                      menuHighlight={highlightMenu}
                    />)
      }
      </div>
    </div>
  );
}

export default App;