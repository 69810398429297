import React, {useState, useRef, useEffect} from 'react';
// import { nativeTouchData } from 'react-dom/cjs/react-dom-test-utils.production.min';
// import { useIsVisible } from 'react-is-visible'


function Drink(props) {

  const ref = useRef();
  // const isVisible = useIsVisible(nodeRef);
  const onScreen = useOnScreen(ref, "-200px");
  const drink_category_data = props.drink_category;
  const drink_sidebar_data = props.drink_category;

    // Hook
function useOnScreen(ref, rootMargin = "0px") {
    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState(false);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Update our state when observer callback fires
          setIntersecting(entry.isIntersecting);
        },
        {
          rootMargin,
        }
      );
      if (ref.current) {
        observer.observe(ref.current);
      }
      return () => {
        observer.unobserve(ref.current);
      };
    }, []); // Empty array ensures that effect is only run on mount and unmount
  
    return isIntersecting;
  }

    return (
        <div className="menu-card" id={props.id}>
                    {/* <div id="tap">On tap</div> */}
                    <div className="menu-card-img-container" ref={ref}>{onScreen && props.visibilityCheck(drink_category_data)}{onScreen && props.menuHighlight(drink_sidebar_data)}
                    <img className="menu-card-img" src={props.img_url} alt=""/>
                    </div>
                    <div className="drink-details">
                        <div className="description-details">
                        <h2 className="d-name">{props.drink_name}</h2>
                        <p className="d-style">{props.drink_type}</p>
                        <p className="d-description">{props.drink_description}</p>
                        <p className="d-abv">{props.abv}</p>
                        </div>
                        <div className="separator"></div>
                        
                        <div className="pricing-details">
                            <p className="d-price">{props.drink_price}</p>
                            <p className="d-size">{props.drink_size}</p>
                        </div>
                    </div>
                </div>
    )
}

export default Drink;