import React from 'react';
// import './App.css';

function Heading(props) {

    const current_menu_item = props.setHighlight

    console.log(current_menu_item);


    function menuClick(event) {


        const yOffset = -300; 
        const element =  document.getElementById(event.target.name);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});

        // document.getElementById(event.target.name).scrollIntoView({ behavior: 'smooth', block: 'center'});
    }

    return (
        <div>
         <nav id="sidebar">
            <div className="sidebar-header">
            </div>

            <ul className="list-unstyled components" id="nav-items">
                <li onClick={menuClick} className="active">
                    <a name="0" className={current_menu_item == 'Beer&Cider' ? 'active-menu' : 'menu-hover'}>
                    <img name="0" className="menu-img" src="./images/icons/beer_icon.svg" alt="" />
                            </a>
                </li>
                <li onClick={menuClick}>
                    <a name="10" className={current_menu_item == 'Wine' ? 'active-menu' : 'menu-hover'}>
                    <img name="10" className="menu-img" src="./images/icons/wine_icon.svg" alt="" /></a>
                </li>
                <li onClick={menuClick}>
                    <a name="11" className={current_menu_item == 'Soft drinks' ? 'active-menu' : 'menu-hover'}>
                    <img name="11" className="menu-img" src="./images/icons/softdrink_icon.svg" alt="" /></a>
                </li>
                <li onClick={menuClick}>
                    <a name="25" className={current_menu_item == 'Coffee&Tea' ? 'active-menu' : 'menu-hover'}>
                    <img name="25" className="menu-img" src="./images/icons/coffee_icon.svg"
                            alt="" /></a>
                </li>
                <li onClick={menuClick}>
                    <a name="30" className={current_menu_item == 'Strong' ? 'active-menu' : 'menu-hover'}>
                    <img name="30" className="menu-img" src="./images/icons/sterk_icon.svg" alt="" /></a>
                </li>
                {/* <li onClick={menuClick}>
                    <a name="30" className={current_menu_item == 'Strong' ? 'active-menu' : 'menu-hover'}
                    ><img name="32" className="menu-img" src="./images/icons/cocktail_icon.svg" alt="" /></a>
                </li>
                
                <li>
                    <a onClick={menuClick} name="plant-a-tree" className="menu-hover"><img name="plant-a-tree" className="menu-img" src="./images/icons/tree.svg" alt="" /></a>
                </li> */}
            </ul>

            </nav>

            <header><img className="logo" src="./images/logos/Amaze_VR_logo_white.png" width="50" height="50" alt="" />
                MENU</header>

            <div className="title-wrapper">
                <h2 className="title">{props.title}</h2>
                {/* <h3 className="subtitle">{props.subtitle}</h3> */}
            </div>
        </div>


    )
}

export default Heading;